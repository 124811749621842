import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TaskDetailsCharacteristics from './TaskDetailsCharacteristics';
import { requestGetFetch, requestPost } from '../../api/request';
import { DOWNTIME, MATERIALS, TASKS, USERS } from '../../api/url';
import ModalSaveResult from './ModalSaveResult';
import ModalStopPurpose from './ModalStopPurpose/ModalStopPurpose';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import {
  formatTime,
  parseAndBoldText,
  SafeHtmlComponent,
  timestampToDateTime,
  timestrToSec,
} from '../../utils/functions';
import PauseIcon from '../../icons/PauseIcon';
import PlayIcon from '../../icons/PlayIcon';
import NewRollModal, { RollSetupParams } from './NewRollModal';
import DefectWindow from './DefectWindow';
import Modal from '../../components/Modal';

export default function TaskDetailsOnce() {
  const {
    accessToken,
    refreshToken,
    userId,
    controlPointId,
    channelWork,
    typeRoute,
    setTypeRoute,
  }: any = useAuth();
  const navigate = useNavigate();

  /**
   * 4 Основные параметры которые получаем с адресной стороки
   */
  const { taskId } = useParams();
  const [searchParams] = useSearchParams();

  //Если мы находимся в задаче на докат
  const parentTaskId = searchParams.get('parentTaskId');
  //Если мы находимся в задаче на производство гладкого листа
  const isFlatSheetProduction = searchParams.get('flatSheet');

  //Если вернулись из доката
  const returnFromChild = searchParams.get('returnFromChild');

  //Состояние загрузчик
  const [loader, setLoader] = useState(false);

  //Список данных добавляются в data
  const [data, setData] = useState<any[]>([]);

  // Выбранная дата
  const [selectedData, setSelectedData] = useState<any>();
  const [pauseTime, setPauseTimer] = useState(false);

  const [activeMenu, setActiveMenu] = useState('сharacteristics');

  const [hourTime, setHourTime] = useState<number>(0);
  const [secondTime, setSecondTime] = useState<number>(0);
  const [minuteTime, setMinuteTime] = useState<number>(0);

  const [timerStart, timerStop] = useState<boolean>(false);

  const selectedRoomRefSecond: any = useRef(0);
  const selectedRoomRefHour: any = useRef(0);
  const selectedRoomRefMinute: any = useRef(0);
  const intervalIDRef = React.useRef<any>(null);

  //Modal form Сохранить текущий результат
  const [modalSaveResultShow, setModalSaveResultShow] = useState(false);
  const [workResults, setWorkResults] = useState({
    materialOperationMatrixId: null,
    quantityDone: null,
    totalLenghtUsed: null,
  });

  //Модальное окно Причина остановки
  const [modalStopPurpose, setModalPurpose] = useState(false);

  const [user, setUser] = useState<any>();

  const [sumMaxTime, setSumMaxTime] = useState<any>();
  const [sumMinTime, setSumMinTime] = useState<any>();

  const [downTimeDate, setDownTimeDate] = useState<any>(null);

  const [continueTimerByStart, setContinueTimerByStart] = useState(false);

  const [isDefectModalOpen, setIsDefectModalOpen] = useState(false);

  const [isTaskFinishConfirmModalOpen, setIsTaskFinishConfirmModalOpen] =
    useState(false);
  const [isFinishMode, setSetIsFinishMode] = useState(false);

  const [currentMaterialName, setCurrentMaterialName] = useState('');

  const getCurrentMaterialId = (): string => {
    const activeMaterialsForControlPoints = JSON.parse(
      localStorage.getItem('activeMaterialsForControlPoints') || ''
    );
    return activeMaterialsForControlPoints
      ? activeMaterialsForControlPoints[controlPointId]?.activeMaterialId
      : null;
  };

  const currentMaterialId = getCurrentMaterialId();

  const reloadTaskInfo = async () => {
    const response = await requestGetFetch(
      TASKS + `${taskId}/`,
      {
        // user_id: userId,
        control_point_id: controlPointId,
      },
      accessToken
    );
    if (response?.data?.length > 0) {
      updateTaskInfo(response);
    }
  };

  const loadMaterialInfo = async () => {
    const response = await requestGetFetch(
      MATERIALS + `${getCurrentMaterialId()}/`,
      {},
      accessToken
    );
    if (response?.data?.length > 0) {
      setCurrentMaterialName(response.data[0]?.roll_id);
    }
  };

  const updateTaskInfo = async (response: any) => {
    setData(response?.data);
    setSelectedData(response?.data[0]);

    if (response?.data[0]?.execution_time != null) {
      const result = timestampToDateTime(response?.data[0]?.execution_time);
      let splitTime = result.split(':');

      if (splitTime.length == 2) {
        splitTime.unshift('00');
      }

      if (splitTime.length > 0) {
        selectedRoomRefHour.current = parseInt(splitTime[0]);
        selectedRoomRefMinute.current = parseInt(splitTime[1]);
        selectedRoomRefSecond.current = parseInt(splitTime[2]);

        setHourTime(parseInt(splitTime[0]));
        setMinuteTime(parseInt(splitTime[1]));
        setSecondTime(parseInt(splitTime[2]));
      }
      timerStop(true);
      setPauseTimer(true);
    }

    if (response?.data[0].downtime?.length > 0) {
      setDownTimeDate(response?.data[0].downtime[0]);
    }

    if (response?.data[0]?.operation?.material_operations?.length > 0) {
      let _sumMinTime = '00:00:00';
      let _sumMaxTime = '00:00:00';
      for (
        let i = 0;
        i < response?.data[0]?.operation?.material_operations?.length;
        i++
      ) {
        _sumMinTime = formatTime(
          timestrToSec(_sumMinTime) +
            timestrToSec(
              response?.data[0]?.operation?.material_operations[i]?.min_time
            )
        );

        _sumMaxTime = formatTime(
          timestrToSec(_sumMaxTime) +
            timestrToSec(
              response?.data[0]?.operation?.material_operations[i]?.max_time
            )
        );
      }
      setSumMaxTime(_sumMaxTime);
      setSumMinTime(_sumMinTime);
    }

    if (response?.data[0]?.materials[0]?.roll_id) {
      setCurrentMaterialName(
        response?.data[0]?.materials[response?.data[0]?.materials.length - 1]
          ?.roll_id
      );
    } else {
      await loadMaterialInfo();
    }
  };

  /**
   * Основная функция отвечает за получени данных о production flow
   */
  const setUpComponentData = async () => {
    setLoader(true);

    const response = await requestGetFetch(
      TASKS + `${taskId}/`,
      {
        // user_id: userId,
        control_point_id: controlPointId,
      },
      accessToken
    );
    if (response?.data?.length > 0) {
      getUser();

      await updateTaskInfo(response);

      if (typeRoute === 'start') {
        if (response?.data[0].downtime?.length == 0) {
          startTimer();
        } else {
          setContinueTimerByStart(true);
        }
      }

      if (returnFromChild) {
        chooseNewMaterial();
      }

      setWorkResults({
        materialOperationMatrixId: null,
        quantityDone: null,
        totalLenghtUsed: null,
      });
    } else {
      setData([]);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (continueTimerByStart == true) {
      continueTimer();
    }
  }, [continueTimerByStart]);

  const getUser = async () => {
    setLoader(true);
    const response = await requestGetFetch(
      USERS + userId + '/',
      {},
      accessToken
    );
    if (response?.data?.length > 0) {
      setUser(response?.data[0]);
    }

    //Проверка токена
    if (response?.error?.length > 0) {
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    setLoader(false);
  };

  /**
   * Эффект случает на изменение таск ид
   */
  useEffect(() => {
    setUpComponentData();
  }, [taskId, accessToken]);

  /**
   * Эффект случает на изменение выбранного записья
   */
  useEffect(() => {
    if (typeof selectedData != 'undefined') {
      if (selectedData?.current_time != null) {
        let splited = selectedData?.current_time.split(':');
        if (splited?.length > 0) {
          let parsedHour = parseInt(splited[0]);
          let parsedMinutes = parseInt(splited[1]);
          let parsedSecond = parseInt(splited[2]);
          selectedRoomRefSecond.current = parsedSecond;
          selectedRoomRefHour.current = parsedHour;
          selectedRoomRefMinute.current = parsedMinutes;
          setHourTime(parsedHour);
          setMinuteTime(parsedMinutes);
          setSecondTime(parsedSecond);
        }
      }
    }
  }, [selectedData]);

  const requestForStartTimer = async () => {
    setLoader(true);
    const response = await requestPost(
      TASKS + taskId + '/start-task/',
      {
        user_id: parseInt(userId),
      },
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
    setLoader(false);
  };

  /**
   * Функция старт таймер
   */
  const startTimer = () => {
    requestForStartTimer();
    timerStop(false);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;

    timerStop(true);
    if (typeof secondTime != 'undefined') {
      intervalIDRef.current = setInterval(() => {
        if (selectedRoomRefSecond.current >= 60) {
          selectedRoomRefSecond.current = 0;
          setSecondTime(1);

          const _timeUpMinute: number = selectedRoomRefMinute?.current + 1;
          selectedRoomRefMinute.current = _timeUpMinute;
          setMinuteTime(_timeUpMinute);
        }

        if (selectedRoomRefMinute.current >= 60) {
          selectedRoomRefMinute.current = 0;
          setMinuteTime(1);

          const _timeUpHour: number = selectedRoomRefHour?.current + 1;
          selectedRoomRefHour.current = _timeUpHour;
          setHourTime(_timeUpHour);
        } else {
          const _selectedRoomRefSecond = selectedRoomRefSecond.current;
          const _timeUp: number = _selectedRoomRefSecond + 1;

          selectedRoomRefSecond.current = _timeUp;
          setSecondTime(_timeUp);
        }
      }, 1000);
    }
  };

  /**
   * Функция отвечает за обновление production flow сумму деталей и времени
   */
  const saveData = async () => {
    setLoader(true);
    let sum: any = 0;
    for (
      let i = 0;
      i < selectedData?.operation?.material_operations?.length;
      i++
    ) {
      sum =
        parseInt(sum) +
        parseInt(selectedData?.operation?.material_operations[i].quantity);
    }

    const response = await requestPost(
      TASKS + `${taskId}/end-task/`,
      {
        user_id: parseInt(userId),
      },
      {},
      accessToken
    );

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
    setLoader(false);
  };

  /**
   * Остановка таймера
   */
  const cancelTimer = async () => {
    timerStop(false);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
    await saveData();
  };

  /**
   * Пауза таймера
   */
  const pauseTimer = () => {
    setModalPurpose(true);
  };

  const pauseTimeAfterModalDone = () => {
    setPauseTimer(true);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
    // updateData();
  };

  const updateDownTime = async () => {
    if (downTimeDate != null && typeof downTimeDate != 'undefined') {
      const response = await requestPost(
        DOWNTIME + `${downTimeDate?.id}/end/`,
        {
          task_id: selectedData?.id,
          user_id: parseInt(userId),
        },
        {},
        accessToken
      );

      if (response?.error?.length > 0) {
        toast.warning(response?.error[0]);
        //Проверка токена
        if (
          response?.error[0] ==
          'detail: Given token not valid for any token type'
        ) {
          refreshToken();
        }

        return false;
      }
    }

    return true;
  };

  /**
   * Продолжить таймер
   */
  const continueTimer = async () => {
    const statusTimer = await updateDownTime();
    //Если статус задачи закрытый то просто закрыть
    if (statusTimer == false) {
      return;
    }

    setPauseTimer(false);

    selectedRoomRefSecond.current = secondTime;
    selectedRoomRefHour.current = hourTime;
    if (typeof secondTime != 'undefined') {
      intervalIDRef.current = setInterval(() => {
        setPauseTimer(false);
        if (selectedRoomRefSecond.current >= 60) {
          selectedRoomRefSecond.current = 0;
          setSecondTime(1);

          const _timeUpMinute: number = selectedRoomRefMinute?.current + 1;
          selectedRoomRefMinute.current = _timeUpMinute;
          setMinuteTime(_timeUpMinute);
        }

        if (selectedRoomRefMinute.current >= 60) {
          selectedRoomRefMinute.current = 0;
          setMinuteTime(1);

          const _timeUpHour: number = selectedRoomRefHour?.current + 1;
          selectedRoomRefHour.current = _timeUpHour;
          setHourTime(_timeUpHour);
        } else {
          const _selectedRoomRefSecond = selectedRoomRefSecond.current;
          const _timeUp: number = _selectedRoomRefSecond + 1;

          selectedRoomRefSecond.current = _timeUp;
          setSecondTime(_timeUp);
        }
      }, 1000);
    }
  };

  const [newRollModalShow, setNewRollModalShow] = useState(false);
  const [newRollId, setNewRollId] = useState<any>(null);

  const [nextRollSetupParams, setNextRollSetupParams] =
    useState<RollSetupParams>({
      taskId: taskId,
      status: 'started',
      length: 0,
    });

  /**
   * Отметить уровень использования рулона и перейти к следующему действию полученному от апи
   */
  const markRollUsageAndGetNextStep = async (rollStatus: string) => {
    setLoader(true);
    if (workResults.totalLenghtUsed === null) {
      toast.warning('Предварительно необходимо сохранить текущий результат');
      setLoader(false);
      throw new Error('Result is not saved before action');
    }

    const currentMaterialId = getCurrentMaterialId();

    if (!currentMaterialId) {
      console.log(
        'Error: no current material set for controlpoint id:' + controlPointId
      );
    }

    const response = await requestPost(
      MATERIALS + 'use-material/',
      {
        task_id: taskId,
        material_id: currentMaterialId,
        status: rollStatus,
        length: workResults.totalLenghtUsed,
      },
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    // откатить на дефолт текущий инкремент в работе для новго расчета дальше
    setWorkResults({
      materialOperationMatrixId: null,
      quantityDone: null,
      totalLenghtUsed: null,
    });

    /* если ответ не пустой - остаток текущего рулона, которого не
      хватит для продолжения текущей задачи, можно использовать в другой задаче. Нужно:
      1) поставить текущую задачу на паузу с причиной "докатка"
      2) перейти к выполнению задачи id которой пришел в ответе
    */
    setLoader(false);
    if (response?.data?.length > 0) {
      return { nextStep: 'MOVE_TO_TASK', taskId: response?.data[0]?.id };
    } else {
      return { nextStep: 'START_FLAT_SHEET' };
    }
  };

  const chooseNewMaterial = () => {
    const newNextRollSetupParams: RollSetupParams = {
      taskId: taskId,
      status: 'started',
      length: 0,
    };
    setNextRollSetupParams(newNextRollSetupParams);
    setNewRollModalShow(true);
  };

  /**
   * Завершить рулон
   */
  const finishRoll = async () => {
    try {
      const result = await markRollUsageAndGetNextStep('used');
      if (result?.nextStep === 'START_FLAT_SHEET') {
        startFlatSheetProduction();
      } else if (result?.nextStep === 'MOVE_TO_TASK') {
        // TODO pause current task
        setTypeRoute('start');
        navigate(`/task-details/${result?.taskId}?parentTaskId=${taskId}`); // перейти в докат
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changeRoll = async () => {
    // TODO pause current task

    setLoader(true);

    const currentMaterialId = getCurrentMaterialId();

    if (!currentMaterialId) {
      console.log(
        'Error: no current material set for controlpoint id:' + controlPointId
      );
    }

    const response = await requestPost(
      MATERIALS + currentMaterialId + '/idle-material/',
      {},
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    } else {
      chooseNewMaterial();
    }
    setLoader(false);
  };

  /**
   * Завершить выполнение задачи
   */
  const finishTask = async () => {
    try {
      let nextNavigate: any;

      if (workResults.totalLenghtUsed === null) {
        toast.warning('Предварительно необходимо сохранить текущий результат');
        throw new Error('Result is not saved before action');
      }

      if (!parentTaskId) {
        const result = await markRollUsageAndGetNextStep('in_progress');

        if (result?.nextStep === 'MOVE_TO_TASK') {
          setTypeRoute('start');
          nextNavigate = `/task-details/${result?.taskId}`;
        }
      } else {
        // TODO call setTypeRoute('continue'); когда добавим постановку задачи на паузу перед переходом к докату
        nextNavigate = `/task-details/${parentTaskId}?returnFromChild=true`;
      }

      await cancelTimer();

      navigate(nextNavigate || -1);
    } catch (e) {
      console.log(e);
    }
  };

  const startFlatSheetProduction = async () => {
    // TODO ставить текущую задачу на паузу

    const response = await requestPost(
      TASKS + `create-task-flat-sheet/`,
      {
        control_point_id: controlPointId,
        material_id: currentMaterialId,
      },
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    if (response?.data[0]?.id) {
      navigate(
        `/task-details/${response?.data[0]?.id}?parentTaskId=${parentTaskId || taskId}&flatSheet=true`
      ); // перейти в докат
    } else {
      toast('Ошибка при старте производства гладкого листа');
    }
  };

  useEffect(() => {
    document.title = 'ПРОметрика';
  }, []);

  return (
    <div className="w-full flex flex-col h-full mt-[59px] ">
      {modalSaveResultShow === true ? (
        <ModalSaveResult
          selectedData={selectedData}
          modalSaveResultShow={modalSaveResultShow}
          setModalSaveResultShow={setModalSaveResultShow}
          userId={userId}
          resultCallback={(savedWorkResults: any) => {
            setWorkResults(savedWorkResults);
          }}
          loadData={reloadTaskInfo}
        />
      ) : null}

      {modalStopPurpose === true ? (
        <ModalStopPurpose
          selectedData={selectedData}
          modalStopPurpose={modalStopPurpose}
          setModalPurpose={setModalPurpose}
          downTimeDate={downTimeDate}
          setDownTimeDate={setDownTimeDate}
          pauseTimeAfterModalDone={pauseTimeAfterModalDone}
          userId={userId}
        />
      ) : null}

      {newRollModalShow === true ? (
        <>
          <NewRollModal
            newRollModalShow={newRollModalShow}
            setNewRollModalShow={setNewRollModalShow}
            rollSubmitParams={nextRollSetupParams}
            currentTaskId={taskId}
            onSubmit={(selectedRollId: string) => {
              setNewRollId(selectedRollId);
              setNewRollModalShow(false);
            }}
            setNewRollSubmitResult={() => {}}
            loadDataTaskData={reloadTaskInfo}
          />
        </>
      ) : null}

      <Modal
        isOpen={isDefectModalOpen}
        onClose={() => setIsDefectModalOpen(false)}
        children={
          <DefectWindow
            taskId={taskId || ''}
            materialId={currentMaterialId}
            onSubmit={(isNewMaterialNeeded: boolean) => {
              if (isNewMaterialNeeded) {
                chooseNewMaterial();
              }
            }}
            setDefectWindowModalShow={(value: boolean) => {
              setIsDefectModalOpen(value);
            }}
          />
        }
      />

      {/*<Modal*/}
      {/*  isOpen={isTaskFinishConfirmModalOpen}*/}
      {/*  onClose={() => setIsTaskFinishConfirmModalOpen(false)}*/}
      {/*  children={*/}
      {/*    <div className="text-center">*/}
      {/*      <div className="text-[32px] text-center">*/}
      {/*        Вы уверены что хотите завершить задачу?*/}
      {/*        <br /> Все листы задачи будут считаться произведёнными*/}
      {/*      </div>*/}

      {/*      <div className="flex w-full gap-[8px] mt-[32px] justify-center">*/}
      {/*        <Button*/}
      {/*          text="Нет"*/}
      {/*          color="secondary"*/}
      {/*          onClick={() => {*/}
      {/*            setIsTaskFinishConfirmModalOpen(false);*/}
      {/*          }}*/}
      {/*        />*/}

      {/*        <Button*/}
      {/*          text="Да"*/}
      {/*          onClick={() => {*/}
      {/*            finishTask().then(() =>*/}
      {/*              setIsTaskFinishConfirmModalOpen(false)*/}
      {/*            );*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*/>*/}

      <div className="w-full flex justify-between   gap-[10px]  text-[20px] font-nunito font-medium bg-white h-full">
        <div className="flex flex-col p-[0_15px_0_52px]">
          <div
            onClick={() => {
              navigate(`/task`);
            }}
            className="flex py-[18px] gap-[10px] cursor-pointer"
          >
            <svg
              width={18}
              height={18}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
            </svg>
            <div className="font-nunito text-[14px] font-medium">
              Назад к списку
            </div>
          </div>

          <div className="relative rounded-[8px] flex gap-[20px] ">
            <div className="w-full ">
              <div className="w-full flex flex-col gap-[24px]">
                <div className="w-full flex flex-col gap-[5px] justify-start items-start w-[646px]">
                  <h3 className="text-[24px] font-medium font-nunito  text-[#252628] ">
                    {selectedData?.operation?.name}
                  </h3>
                  <p className="font-nunito text-[16px] text-start">
                    <SafeHtmlComponent
                      html={parseAndBoldText(
                        selectedData?.operation?.description || ''
                      )}
                    />
                  </p>
                </div>

                <Button
                  text="Сообщить о браке"
                  color="secondary"
                  height="70px"
                  onClick={() => {
                    setIsDefectModalOpen(true);
                  }}
                />

                <div className="">
                  {/*{activeMenu === 'instruction' ? (*/}
                  {/*  <TaskDetailsInstructions />*/}
                  {/*) : null}*/}

                  {/*{activeMenu === 'сharacteristics' ? (*/}
                  <TaskDetailsCharacteristics
                    selectedData={selectedData}
                    freeQuantity={isFlatSheetProduction}
                    // setIsTaskFinishConfirmModalOpen={
                    //   setIsTaskFinishConfirmModalOpen
                    // }
                    // isFinishMode={isFinishMode}
                  />
                  {/*) : null}*/}

                  {/*{activeMenu === 'necessary_materials' ? (*/}
                  {/*  <TaskDetailsNecessaryMaterials*/}
                  {/*    selectedData={selectedData}*/}
                  {/*  />*/}
                  {/*) : null}*/}

                  {/*{activeMenu === 'blueprints' ? (*/}
                  {/*  <TaskDetailsBlueprints />*/}
                  {/*) : null}*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Rigth side */}
        <div className="border-t-[1px] border-[#cfcfcf] flex flex-col justify-between h-[80vh] w-[480px] bg-[#F8F8F8] ">
          <div className="flex flex-col gap-[30px] justify-between h-full">
            <div className="flex flex-col gap-[12px]">
              <div className="w-full flex flex-col gap-[8px] px-[31px] mt-[25px]">
                <p className="font-nunito text-[28px] font-medium">
                  Задача {selectedData?.id}
                </p>
                <div className="w-full flex flex-col gap-[8px]">
                  <div className="w-full flex flex-col gap-[5px] ">
                    <p className="font-nunito text-[16px] font-normal leading-[16px]">
                      Ответственный
                    </p>
                    <p className="font-nunito text-[16px] font-normal leading-[16px]">
                      <b>{user?.name}</b>
                    </p>
                  </div>
                  {/*<div className="w-full flex flex-col gap-[5px]">*/}
                  {/*  <p className="font-nunito text-[16px] font-normal leading-[16px]">*/}
                  {/*    Минимальное плановое время выполнения: <b>{sumMinTime}</b>*/}
                  {/*  </p>*/}
                  {/*  <p className="font-nunito text-[16px] font-normal leading-[16px]">*/}
                  {/*    Максимальное плановое время выполнения:{' '}*/}
                  {/*    <b>{sumMaxTime}</b>*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                </div>
              </div>
              <div className="w-full flex flex-col px-[31px] gap-[9px]">
                <Button
                  text="Добавить выполненное количество"
                  color="secondary"
                  height="70px"
                  onClick={() => setModalSaveResultShow(true)}
                />
                <div className="flex flex-col mt-[10px] w-full gap-[8px]">
                  {timerStart && !parentTaskId && (
                    <Button
                      text="Начать докат"
                      onClick={() => finishRoll()}
                      height="30px"
                    />
                  )}
                  {timerStart && !parentTaskId && (
                    <Button
                      text="Снять рулон"
                      onClick={() => changeRoll()}
                      height="30px"
                      color="secondary"
                    />
                  )}
                  {timerStart && parentTaskId && !isFlatSheetProduction && (
                    <Button
                      text="Произвести гладкий лист"
                      onClick={() => startFlatSheetProduction()}
                      height="30px"
                    />
                  )}
                </div>

                {currentMaterialName && (
                  <div>
                    <span className="text-[black] px-[5px] text-[24px]  text-start font-bold">
                      Используемый рулон:
                    </span>{' '}
                    <br />
                    <span>{currentMaterialName}</span>
                  </div>
                )}
              </div>
            </div>

            {/*{timerStart ? (*/}
            {/*  <div className="py-[13px] w-full flex flex-col justify-center items-center  border">*/}
            {/*    <p className="font-nunito text-[18px]">*/}
            {/*      Время работы над задачей*/}
            {/*    </p>*/}
            {/*    <p className="font-nunito text-[28px]">*/}
            {/*      {hourTime < 10 ? '0' + hourTime : hourTime}:*/}
            {/*      {minuteTime < 10 ? '0' + minuteTime : minuteTime}:*/}
            {/*      {secondTime < 10 ? '0' + secondTime : secondTime}*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*) : null}*/}
          </div>
          <div className="w-full flex flex-col px">
            {/*{!timerStart ? (*/}
            {/*  <div className="py-[13px] w-full flex flex-col justify-center items-center bg-white border">*/}
            {/*    <p className="font-nunito text-[18px]">*/}
            {/*      Время работы над задачей*/}
            {/*    </p>*/}
            {/*    <p className="font-nunito text-[28px]">*/}
            {/*      {hourTime < 10 ? '0' + hourTime : hourTime}:*/}
            {/*      {minuteTime < 10 ? '0' + minuteTime : minuteTime}:*/}
            {/*      {secondTime < 10 ? '0' + secondTime : secondTime}*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*) : null}*/}

            {!timerStart ? (
              <Button
                text="Начать"
                color="black"
                noRadius
                onClick={() => {
                  startTimer();
                }}
                height="30px"
              />
            ) : (
              <div style={{ display: 'flex' }}>
                {!pauseTime ? (
                  <div
                    onClick={() => {
                      pauseTimer();
                    }}
                  >
                    <PauseIcon />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      continueTimer();
                    }}
                  >
                    <PlayIcon />
                  </div>
                )}

                <div
                  onClick={() => {
                    // isTaskFinishConfirmModalOpen && finishTask();
                    // setIsTaskFinishConfirmModalOpen(true);
                    // setSetIsFinishMode(true);
                    finishTask();
                  }}
                  style={{ width: '100%' }}
                >
                  <Button
                    text="Завершить"
                    color="black"
                    height="122px"
                    noRadius
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
