import React, { useEffect, useState } from 'react';
import { requestPostReadyItems } from '../../api/request';
import { TASKS } from '../../api/url';
import { useAuth } from '../../hooks/useAuth';
import { loaderSvg } from '../../utils/functions';
import Counter from '../../components/Counter';
import { toast } from 'react-toastify';

export default function ModalSaveResult({
  selectedData,
  modalSaveResultShow,
  setModalSaveResultShow,
  userId,
  resultCallback,
  loadData,
}: any) {
  const { accessToken, refreshToken }: any = useAuth();
  const [loader, setLoader] = useState(false);
  const [currentCount, setCurrentCount] = useState<any>(0);
  const [selectedOption, setSelectedOption] = useState<any>();

  const upCount = () => {
    let _currentCount = parseInt(currentCount) + 1;
    setCurrentCount(_currentCount);
  };

  const downCount = () => {
    let _currentCount = parseInt(currentCount) - 1;
    setCurrentCount(_currentCount);
  };

  useEffect(() => {
    if (typeof selectedData != 'undefined') {
      if (selectedData?.operation?.material_operations?.length > 0) {
        for (
          let i = 0;
          i < selectedData?.operation?.material_operations?.length;
          i++
        ) {
          if (
            selectedData?.operation?.material_operations[i]?.type_operation ==
            'stop'
          ) {
            setSelectedOption(selectedData?.operation?.material_operations[i]);
            setCurrentCount(
              selectedData?.operation?.material_operations[i].quantity
            );
          }
        }
      }
    }
  }, [selectedData, accessToken]);

  const handleChangeOption = (e: any) => {
    const selectedId = e.target?.value;
    for (
      let i = 0;
      i < selectedData?.operation?.material_operations?.length;
      i++
    ) {
      if (selectedData?.operation?.material_operations[i]?.id == selectedId) {
        setSelectedOption(selectedData?.operation?.material_operations[i]);
        setCurrentCount(
          selectedData?.operation?.material_operations[i].quantity
        );
      }
    }
  };

  const saveData = async () => {
    setLoader(true);
    let sum: any = 0;
    for (
      let i = 0;
      i < selectedData?.operation?.material_operations?.length;
      i++
    ) {
      if (
        selectedData?.operation?.material_operations[i]?.id ==
        selectedOption?.id
      ) {
        sum = parseInt(currentCount) + parseInt(sum);
      } else {
        sum =
          parseInt(sum) +
          parseInt(selectedData?.operation?.material_operations[i].quantity);
      }
    }

    const materialOperationMatrixId = selectedOption?.id;
    const quantityDone = parseInt(currentCount);

    const itemLength = selectedOption?.item?.length;
    const totalLenghtUsed = quantityDone * itemLength;

    const response = await requestPostReadyItems(
      TASKS + `${selectedData?.id}/add-ready-items/`,
      {
        materials_operation: [
          {
            material_operation_matrix_id: materialOperationMatrixId,
            quantity_done: quantityDone,
          },
        ],
        user_id: userId,
      },
      {},
      accessToken
    );

    //Проверка на обновление токена
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    setLoader(false);
    resultCallback({
      materialOperationMatrixId,
      quantityDone,
      totalLenghtUsed,
    });
    setModalSaveResultShow(!modalSaveResultShow);
    await loadData();
  };

  return (
    <div className="absolute left-0 right-0 top-0 z-50 flex h-[calc(100%-1rem)] max-h-full w-full items-center justify-center  overflow-y-auto  overflow-x-hidden text-center ">
      <div className="relative max-h-full w-full max-w-[591px] p-4">
        <div className="relative rounded-[8px] bg-white shadow flex flex-col gap-[20px] ">
          <div className="w-full flex justify-end items-end px-[12px]">
            <button
              onClick={() => setModalSaveResultShow(!modalSaveResultShow)}
              type="button"
              className="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
          <div className="w-full px-[32px]">
            <div className="w-full flex flex-col gap-[24px]">
              <div className="w-full flex flex-col gap-[5px] justify-start items-start">
                <h3 className="text-[18px] font-medium font-nunito  text-[#252628] ">
                  Добавить выполненное количество
                </h3>
                <p className="font-nunito text-[14px] text-start">
                  Выберите изделие, которое хотите отметить как готовое
                </p>
              </div>
              <div className="w-full flex flex-col gap-[10px]">
                <div className="w-full flex bg-[#F2F3F5] p-[3px]  text-[14px]">
                  <select
                    onChange={(e) => handleChangeOption(e)}
                    className="w-full px-[10px] py-[15px] rounded-[4px]"
                  >
                    {selectedData?.operation?.material_operations?.length > 0 &&
                      selectedData?.operation?.material_operations?.map(
                        (value: any, index: number) => {
                          if (value?.type_operation === 'stop') {
                            if (value?.id == selectedOption?.id) {
                              return (
                                <option selected key={index} value={value?.id}>
                                  {value?.item?.name}
                                </option>
                              );
                            } else {
                              return (
                                <option key={index} value={value?.id}>
                                  {value?.item?.name}
                                </option>
                              );
                            }
                          }
                        }
                      )}
                  </select>
                </div>

                <div className="w-full justify-start items-start flex flex-col gap-[5px]">
                  <p className="text-[14px]">
                    Введите количество добавляемых готовых изделий
                  </p>
                  <Counter
                    upCount={() => upCount()}
                    downCount={() => downCount()}
                    value={currentCount}
                    setValue={setCurrentCount}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full  flex gap-[10px] px-[30px] py-[10px]">
            <div
              onClick={() => setModalSaveResultShow(!modalSaveResultShow)}
              className="py-[10px] active:bg-[#5870ed] hover:bg-[#768aed] w-1/2 border border-[#cfcfcf] rounded-[4px]  flex flex-col justify-center items-center"
            >
              <div className="select-none   py-[13px] w-1/2  flex flex-col justify-center items-center cursor-pointer">
                <p className="select-none font-nunito text-[28px]">Отменить</p>
              </div>
            </div>
            <div
              onClick={() => {
                saveData();
              }}
              className="select-none py-[10px] rounded-[4px] w-1/2 bg-[#4E67EA] active:bg-[#5870ed] hover:bg-[#768aed] flex flex-col justify-center items-center cursor-pointer"
            >
              <p className="select-none font-nunito text-[28px] text-white">
                {loader == true ? loaderSvg() : 'Отправить'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
