import React from 'react';

interface IButtonProps {
  text: string;
  onClick?: () => void;
  color?: 'primary' | 'secondary' | 'black';
  height?: string;
  noRadius?: boolean;
  disabled?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  text,
  onClick,
  color = 'primary',
  height = '86px',
  noRadius = false,
  disabled = false,
}) => {
  const styles = {
    primary: {
      backgroundColor: disabled ? 'bg-[#F2F3F5]' : 'bg-[#4E67EA]',
      textColor: disabled ? 'text-[#C0C1C3]' : 'text-white',
      hoverBgColor: disabled ? '' : 'hover:bg-[#768aed]',
      activeBgColor: disabled ? '' : 'active:bg-[#5870ed]',
    },
    secondary: {
      backgroundColor: disabled ? 'bg-[#F2F3F5]' : 'bg-[#E6E6E6]',
      textColor: disabled ? 'text-[#C0C1C3]' : 'text-[#00000099]',
      hoverBgColor: '',
      activeBgColor: '',
    },
    black: {
      backgroundColor: disabled ? 'bg-[#F2F3F5]' : 'bg-[#000000]',
      textColor: disabled ? 'text-[#C0C1C3]' : 'text-white',
      hoverBgColor: '',
      activeBgColor: '',
    },
  }[color];

  const borderRadiusClass = noRadius ? '' : 'rounded-md';

  return (
    <button
      onClick={!disabled ? onClick : undefined}
      style={{ minHeight: height, lineHeight: '22px', padding: '24px' }}
      className={`select-none w-full ${styles.backgroundColor} ${styles.activeBgColor} ${styles.hoverBgColor} flex flex-col justify-center items-center cursor-pointer text-[24px] text-nowrap ${styles.textColor} font-nunito border-none ${borderRadiusClass}`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
