import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestGetFetch, requestPost } from '../../api/request';
import { SHIFT, USERS } from '../../api/url';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';

export default function Login() {
  const {
    accessToken,
    refreshToken,
    controlPointId,
    userId,
    setUserId,
    channelWork,
    setChannelWork,
  }: any = useAuth();

  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [cChannelWork, sSetChannelWork] = useState<string>('');

  const [shiftId, setShiftId] = useState<any>();

  const getData = async () => {
    const response = await requestGetFetch(
      USERS,
      {
        control_point_id: controlPointId,
      },
      accessToken
    );
    if (response?.data?.length > 0) {
      setData(response?.data);
      setSelectedData(response?.data[0]);
      setUserId(response?.data[0]?.id);
    }

    if (response?.error?.length > 0) {
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
  };

  useEffect(() => {
    document.title = 'ПРОметрика';
    getData();
  }, [accessToken, controlPointId]);

  const currentNavigateUser = (e: any) => {
    e.preventDefault();
    if (selectedData == null) {
      return;
    }
    startShift();
  };

  const startShift = async () => {
    const response = await requestPost(
      SHIFT,
      {
        user_id: selectedData?.id,
        shift_number: channelWork,
      },
      {},
      accessToken
    );

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    navigate(`/welcome-user`);
  };

  useEffect(() => {
    setChannelWork('');
  }, [selectedData]);

  const onChangeUser = (e: any) => {
    const currentUserId = e.target.value;
    const foundedUserId = data?.filter((v) => v?.id == currentUserId);
    if (foundedUserId?.length > 0) {
      setSelectedData(foundedUserId[0]);
      setUserId(foundedUserId[0]?.id);
    }
  };

  return (
    <div className="w-full bg-[#EEEEEE] flex justify-center items-center py-[195px] h-full">
      <div className="flex flex-col gap-[32px] w-[536px] bg-white p-[32px] rounded-[8px]">
        <div
          data-testid="login-container"
          className="text-[28px] font-nunito font-semibold text-center"
        >
          Выберите пользователя
        </div>
        <div className="flex flex-col gap-[24px]  w-full">
          <div className="flex justify-center items-center  h-[48px] border rounded-[8px] px-[10px]">
            <select
              onChange={(e) => {
                onChangeUser(e);
              }}
              className="w-[500px] h-[38px]"
            >
              {data?.length > 0 &&
                data?.map((value, index) => {
                  if (selectedData?.id === value?.id) {
                    return (
                      <option
                        defaultValue={value?.id}
                        key={index}
                        value={value?.id}
                      >
                        {value?.name}
                      </option>
                    );
                  } else {
                    return (
                      <option key={index} value={value?.id}>
                        {value?.name}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
          <div className="w-full flex gap-[10px] text-[#C0C1C3] text-[16px] font-nunito ">
            {selectedData?.shift?.length > 0 ? (
              <>
                {selectedData?.shift?.map((value: any, index: number) => {
                  if (value?.shift_number == 'first') {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          sSetChannelWork('first');
                          setChannelWork('first');
                          setShiftId(value?.id);
                        }}
                        className={
                          'cursor-pointer hover:bg-[#4E67EA] transition-all ease-linear hover:text-white rounded-[4px]  px-[12px] py-[8px] flex grow text-center justify-center items-center ' +
                          (cChannelWork == 'first'
                            ? 'bg-[#4E67EA] text-white'
                            : 'bg-[#F8F8F8]')
                        }
                      >
                        Ⅰ смена
                      </div>
                    );
                  }

                  if (value?.shift_number == 'second') {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          sSetChannelWork('second');
                          setChannelWork('second');
                          setShiftId(value?.id);
                        }}
                        className={
                          'cursor-pointer hover:bg-[#4E67EA] transition-all ease-linear hover:text-white rounded-[4px]  px-[12px] py-[8px] flex grow text-center justify-center items-center ' +
                          (cChannelWork == 'second'
                            ? 'bg-[#4E67EA] text-white'
                            : 'bg-[#F8F8F8]')
                        }
                      >
                        Ⅱ смена
                      </div>
                    );
                  }

                  if (value?.shift_number == 'third') {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          sSetChannelWork('third');
                          setChannelWork('third');
                          setShiftId(value?.id);
                        }}
                        className={
                          'cursor-pointer hover:bg-[#4E67EA] transition-all ease-linear hover:text-white rounded-[4px]  px-[12px] py-[8px] flex grow text-center justify-center items-center  ' +
                          (cChannelWork == 'third'
                            ? 'bg-[#4E67EA] text-white'
                            : 'bg-[#F8F8F8]')
                        }
                      >
                        Ⅲ смена
                      </div>
                    );
                  }
                  return;
                })}
              </>
            ) : (
              <>
                <div
                  onClick={() => {
                    sSetChannelWork('first');
                    setChannelWork('first');
                  }}
                  className={
                    'cursor-pointer hover:bg-[#4E67EA] transition-all ease-linear hover:text-white rounded-[4px]  px-[12px] py-[8px] flex grow text-center justify-center items-center ' +
                    (cChannelWork == 'first'
                      ? 'bg-[#4E67EA] text-white'
                      : 'bg-[#F8F8F8]')
                  }
                >
                  Ⅰ смена
                </div>
                <div
                  onClick={() => {
                    sSetChannelWork('second');
                    setChannelWork('second');
                  }}
                  className={
                    'cursor-pointer hover:bg-[#4E67EA] transition-all ease-linear hover:text-white rounded-[4px]  px-[12px] py-[8px] flex grow text-center justify-center items-center ' +
                    (cChannelWork == 'second'
                      ? 'bg-[#4E67EA] text-white'
                      : 'bg-[#F8F8F8]')
                  }
                >
                  Ⅱ смена
                </div>
                <div
                  onClick={() => {
                    sSetChannelWork('third');
                    setChannelWork('third');
                  }}
                  className={
                    'cursor-pointer hover:bg-[#4E67EA] transition-all ease-linear hover:text-white rounded-[4px]  px-[12px] py-[8px] flex grow text-center justify-center items-center  ' +
                    (cChannelWork == 'third'
                      ? 'bg-[#4E67EA] text-white'
                      : 'bg-[#F8F8F8]')
                  }
                >
                  Ⅲ смена
                </div>
              </>
            )}
          </div>
          {cChannelWork != '' ? (
            <button
              onClick={(e) => {
                currentNavigateUser(e);
              }}
              className={
                'text-center w-full h-[48px] border rounded-[8px] px-[10px]  leading-[24px] ' +
                (selectedData != null
                  ? 'bg-[#4E67EA] text-white '
                  : 'bg-[#F2F3F5] disabled text-[#D9DADC] cursor-not-allowed ')
              }
            >
              Продолжить
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
