import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './view/Home/Home.';
import Login from './view/Login/Login';
import Task from './view/Task/Task';
import TaskDetailsOnce from './view/Task/TaskDetailsOnce';
import WelcomeUser from './view/WelcomeUser/WelcomeUser';
import Header from './view/Partials/Header';
import ControlPoints from './view/ControlPoints/ControlPoints';
import ToastProvider from './providers/toast.provider';
import { AuthProvider } from './hooks/useAuth';
import { ProtectedRoute } from './ProtectedRoute';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {
  const queryClient = new QueryClient();

  return (
    <ToastProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Header />
            <Routes>
              {/* Start Home page */}
              <Route index path="/" element={<Home />}></Route>
              {/* <Route path="/dashboard" element={<AuthGuard Component={<p>Dashboard</p>} />} /> */}

              <Route
                path="/control-points"
                element={
                  <ProtectedRoute>
                    <ControlPoints />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/login"
                element={
                  <ProtectedRoute>
                    <Login />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/welcome-user"
                element={
                  <ProtectedRoute>
                    <WelcomeUser />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/task"
                element={
                  <ProtectedRoute>
                    <Task />
                  </ProtectedRoute>
                }
              ></Route>

              {/* <Route path="/task" element={<Task />}>
              <ProtectedRoute>
                <Route index element={<Task />} />
              </ProtectedRoute>
            </Route> */}

              <Route
                path="/task-details/:taskId"
                element={
                  <ProtectedRoute>
                    <TaskDetailsOnce />
                  </ProtectedRoute>
                }
              />
            </Routes>
            {/* <Footer number={10} /> */}
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
