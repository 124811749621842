import { useEffect, useState } from 'react';
import { requestGetFetch } from '../../api/request';
import { CONTROL_POINTS } from '../../api/url';
import ControlPoint from '../Home/ControlPoint/ControlPoint';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { loaderSvg } from '../../utils/functions';

export default function ControlPoints() {
  const { accessToken, refreshToken, setControlPointId, controlPointId }: any =
    useAuth();

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const getData = async () => {
    setLoader(true);
    const response = await requestGetFetch(CONTROL_POINTS, {}, accessToken);
    if (response?.data?.length > 0) {
      setData(response?.data);
      setLoader(false);
    }
    if (response?.error?.length > 0) {
      //Проверка токена
      toast.error(response?.error[0]);
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
  };

  useEffect(() => {
    document.title = 'ПРОметрика';
    getData();
  }, []);

  return (
    <div className="relative top-[59px] w-full h-auto min-h-full flex flex-col gap-[56px] m-auto justify-start items-center py-[14px] bg-[#F8F8F8]">
      <div className="flex flex-col gap-[32px]">
        <h1 className="font-nunito text-[24px] leading-[52px] font-semibold text-center">
          Выберите контрольную точку
        </h1>
        <div className="flex w-full justify-center items-center">
          {loader ? (
            loaderSvg()
          ) : (
            <div className="flex flex-wrap max-w-[900px] gap-[35px] justify-center overflow-y-auto">
              {data?.length > 0 &&
                data.map((value, index) => {
                  return (
                    <ControlPoint
                      id={value.id}
                      description={value.description}
                      image={value.image}
                      key={index}
                      activeMaterialId={value.active_material_id}
                      activeTaskId={value.active_task_id}
                      setControlPointId={setControlPointId}
                      controlPointId={controlPointId}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
