import { useNavigate } from "react-router-dom";
import { ControlPointTypes } from "./ControlPoint.types";
import { setActiveMaterialToCache } from "../../../utils/functions";

export default function ControlPoint(props: ControlPointTypes) {
  const navigation = useNavigate();
  const { setControlPointId, activeMaterialId, activeTaskId } = props;
  return (
    <button
      data-testid="control-point-container"
      onClick={() => {
        setControlPointId(props.id);
        
        setActiveMaterialToCache({
          controlPointId: props.id,
          activeMaterialId,
          activeTaskId
        })

        navigation(`/login`);
      }}
      className="flex justify-center items-center flex-col gap-[15px] w-[230px] h-auto py-[10px] border border-gray-400 cursor-pointer "
    >
      <div
        data-testid="image-container"
        className="w-[198px] h-[154px] bg-gray-100 "
      >
        {props?.image != null ? (
          <img
            data-testid="image"
            alt={props?.description}
            className="w-[198px] h-[154px] bg-no-repeat rounded-[4px] "
            src={props?.image}
          />
        ) : null}
      </div>

      <div
        data-testid="description"
        className="w-fill  font-nunito font-bold text-[18px] leading-[19px] text-center"
      >
        {props.description}
      </div>
    </button>
  );
}
