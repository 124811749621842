export default function ArrowUpIcon({ w, h, disabled = false }: any) {
  return (
    <svg
      width={w || '32'}
      height={h || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 19L16 13L22 19"
        stroke={disabled ? '#0000004D' : '#464D58'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
